import { graphql } from 'gatsby'
import { Tooltip } from 'react-tooltip'
import CalendarItem from "../../components/calendar/item"
import Container from "../../components/container"
import EventTeaser from "../../components/events/teaser"
import Icon from "../../components/icon"
import InfoIcon from "../../images/svgs/icon-info.svg"
import React, { useState } from "react"
import tw from "twin.macro"
import Seo from "../../components/seo"

const isHomePage = false
const location = {
  path: '/events',
}

const EventList = ({
    data,
  }) => {

  var first_hour = 9
  var last_hour = 0

  const [showAll, setShowAll] = useState(true)
  const [showVertical, setShowVertical] = useState(true)

  const events = data.events.edges.map(( node ) => {
    const start_date = new Date(node.event.startDate)
    const end_date = new Date(node.event.endDate)
    node.event.venuetitle = node.event.venue?.title
    node.event.minutes = Math.floor((Math.abs(end_date - start_date)/1000)/60)
    node.event.startHour = start_date.getHours()
    node.event.startMinute = start_date.getMinutes()
    node.event.schedule = start_date.getHours() + ':' + start_date.getMinutes() + ' ' + end_date.getHours() + ':' + end_date.getMinutes()
    node.event.scheduleStart = start_date.getHours() + ':' + start_date.getMinutes().toString().padStart(2, "0")
    node.event.scheduleEnd = end_date.getHours() + ':' + end_date.getMinutes().toString().padStart(2, "0")
    node.event.category = node.event.eventsCategories.nodes ? node.event.eventsCategories.nodes[0] : 'no-name'
    node.event.organizer = node.event.organizers.nodes ? node.event.organizers.nodes[0] : ''
    node.event.tag = node.event.tags.nodes ? node.event.tags.nodes[0] : ''
    last_hour = last_hour < start_date.getHours() ? start_date.getHours() : last_hour
    return node.event
  })

  var sortOrder = ['C 01', 'A 03', 'A 04', 'A 05', 'A 06', 'A 08', 'B 04', 'B 05', 'B 08', 'B 09']
  events.sort(function(a, b){
    return sortOrder.indexOf(a.venuetitle) - sortOrder.indexOf(b.venuetitle)
  })

  const venues = Object.entries(groupByVenue(events))
  function groupByVenue(objectArray)
  {
    return objectArray.reduce((acc, obj) => {
       const key = obj.venue?.title
       if (!acc[key]) {
          acc[key] = [];
       }
       acc[key].push(obj)
       return acc
    }, {});
  }

  const eventsByHours = Object.entries(groupByStartHour(events))
  function groupByStartHour(objectArray)
  {
    return objectArray.reduce((result, item) => {
      const startHour = item.startHour;
      result[startHour] = result[startHour] || [];
      result[startHour].push([
        item.venuetitle,
        item
      ])
      return result
    }, {})
  }

  const schedule = Array.from({ length: (last_hour - first_hour) + 3 }, () => first_hour ++)

  return (
    <>
      {/*<Seo title={data.seo.title} description={data.seo.excerpt} lang="de" />*/}
      <Container>

        <div tw="columns-1 md:columns-2 lg:columns-2 mb-10">
          <div tw="">
            <h1 tw="text-3xl font-bold lg:text-5xl">Programmübersicht</h1>
          </div>
          <div tw="text-end md:text-end lg:text-end text-xl font-bold">
            <div className="custom-select">
                <select onChange={() => setShowVertical(!showVertical)}>
                  <option value="Verticale">Vertikale Ansicht</option>
                  <option value="Horizontale">Horizontale Ansicht</option>
                </select>
              </div>
          </div>
        </div>

        {!showVertical && (

          <div tw="mb-10 flex lg:mb-18 w-full right-0 ">
            <div tw="w-full text-end font-bold text-xl ">
              Legende
              <a tw="inline-flex"
                data-tooltip-id="tooltip"
                data-tooltip-html="<div style='border: 2px solid black; padding: 14px;'>
                  <div style='padding: 8px;display: flex;'>
                    <div style='width:28px; height:28px; background-color:#C7B5FF;display:inline; padding-top: 12px;'></div>
                    <div style='margin-left: 14px;display:inline; align:left;'>Formate der Bundesstiftung Gleichstellung</div>
                  </div>
                  <div style='padding: 8px;display: flex;'>
                    <div style='width:28px; height:28px; background-color:#A6BDCC;display:inline; padding-top: 12px;'></div>
                    <div style='margin-left: 14px;display:inline; align:left;'>Panels und Workshops</div>
                  </div>
                  <div style='padding: 8px;display: flex;'>
                    <div style='width:28px; height:28px; background-color:#FFF379;display:inline; padding-top: 12px;'></div>
                    <div style='margin-left: 14px;display:inline; align:left;'>Kurze Formate</div>
                  </div>
                  <div style='padding: 8px;display: flex;'>
                    <div style='width:28px; height:28px; background-color:#F2EBE8;display:inline; padding-top: 12px;'></div>
                    <div style='margin-left: 14px;display:inline; align:left;'>Pausen</div>
                  </div>
                </div>"
              >
                <Icon svg={InfoIcon} tw="h-4 w-4 ml-2" />
              </a>
              <Tooltip
                id="tooltip"
                style={{ backgroundColor: "rgb(255,255,255)", color: "#222" }}
                opacity={1}
              />
            </div>
          </div>
        )}

      </Container>


      {showVertical
        ?
          <Container>
            <ul tw="mb-11 border-b-3 border-black">
              {data.events.edges &&
                data.events.edges.map( ({event}, index) => (
                  <li
                    tw="border-t-3 border-black py-14 md:px-6 lg:px-11"
                    css={[showAll || index < 3 ? tw`block` : tw`hidden`]}
                    key={`event-${event.id}`}
                  >
                    <EventTeaser {...event} />
                  </li>
                ))}
            </ul>
          </Container>
        :
          <Container>
            <div tw="h-auto w-full overflow-x-scroll min-h-[2100px]">
              <table tw="table-fixed border-spacing-2 border-separate w-fit mb-11">

                <thead>
                  <tr>
                    <th tw="p-3 w-24 font-bold">Raum</th>
                    {venues.map((venue) => (
                      <th tw="p-3 bg-sand-300 w-72 font-bold">{venue[0]}</th>
                    ))}

                  </tr>
                </thead>

                <tbody>

                  {schedule.map((hour, index) => (

                    <tr tw="">

                      <td tw="border-r-3 border-r-gray-800 pr-2 h-[144px] relative">
                        <div tw="absolute text-right top-0 right-0 pr-2">
                          {hour}:00
                        </div>
                      </td>

                      {eventsByHours.findIndex(x => x[0] == hour) > -1
                        ?
                          <>
                            {venues.map((venue) => (
                              <>
                              {eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0]) > -1
                                ?
                                  <>
                                    <CalendarItem
                                      title={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .title
                                      }
                                      venue={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .venuetitle
                                      }
                                      start={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .startMinute
                                      }
                                      duration={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .minutes
                                      }
                                      schedulestart={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .scheduleStart
                                      }
                                      scheduleend={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .scheduleEnd
                                      }
                                      color={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .category
                                      }
                                      organizer={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .organizer.title
                                      }
                                      uri={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .uri
                                      }
                                      tag={eventsByHours
                                        [eventsByHours.findIndex(x => x[0] == hour)][1]
                                        [eventsByHours[eventsByHours.findIndex(x => x[0] == hour)][1].findIndex(x => x[0] == venue[0])][1]
                                        .tag
                                      }
                                    />
                                  </>
                                :
                                  <CalendarItem empty />
                              }
                              </>
                            ))}

                          </>
                        :
                          <>
                            {hour == 9 &&

                              <td colspan="10" tw="bg-sand-500">
                                <div tw="relative h-[144px]">
                                  <div tw="absolute p-2 text-left top-0 left-0">
                                    <div tw="text-sm font-bold">Ankommen und Registrierung</div>
                                    <div tw="text-sm">in Ebene A</div>
                                    <div tw="">9:00–10:00 Uhr</div>
                                  </div>
                                </div>
                              </td>
                            }

                            {hour == 12 &&
                              <td colspan="10" tw="relative">
                                <div tw="absolute w-full h-[110px] top-[112px] bg-sand-500">
                                  <div tw="p-2 text-left top-0 left-0">
                                    <div tw="text-sm font-bold">Mittagspause</div>
                                    <div tw="text-sm">im Foyer auf Ebene B und C</div>
                                    <div tw="">12:45–13:30 Uhr</div>
                                  </div>
                                </div>
                              </td>
                            }

                            {false && hour == 15 &&
                              <td colspan="10" tw="">
                                <div tw=" w-full h-[0px] top-[0px] bg-sand-500">
                                  <div tw="p-2 text-left top-0 left-0">
                                    <div tw="text-sm font-bold">Kaffeepause</div>
                                    <div tw="text-sm">im Foyer auf Ebene B und C</div>
                                    <div tw="">15:00–15:30 Uhr</div>
                                  </div>
                                </div>
                              </td>
                            }

                            {hour == 17 &&
                              <td colspan="10" tw="relative z-0">
                                <div tw="absolute w-full h-[296px] top-[0px] bg-sand-500">
                                  <div tw="p-2 text-left top-0 left-0">
                                    <div tw="text-sm font-bold">Gemeinsames Abendessen</div>
                                    <div tw="text-sm">im Foyer auf Ebene B und C</div>
                                    <div tw="">17:00–19:00 Uhr</div>
                                  </div>
                                </div>
                              </td>
                            }

                            {hour == 20 &&

                              <td colspan="10" tw="relative z-0">
                                <div tw="absolute w-full h-[222px] top-[74px] bg-sand-500">
                                  <div tw="p-2 text-left top-0 left-0">
                                    <div tw="text-sm font-bold">Get-together</div>
                                    <div tw="text-sm">im Foyer auf Ebene B und C</div>
                                    <div tw="">20:30–22:00 Uhr</div>
                                  </div>
                                </div>
                              </td>
                            }

                            {hour == 21 &&

                            <td colspan="10" tw="relative z-0">
                              <div tw="absolute w-full h-[70px] -top-[912px] bg-sand-500">
                                <div tw="p-2 text-left top-0 left-0">
                                  <div tw="text-sm font-bold">Kaffeepause</div>
                                  <div tw="text-sm">im Foyer auf Ebene B und C</div>
                                  <div tw="">15:00–15:30 Uhr</div>
                                </div>
                              </div>
                            </td>
                            }
                          </>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Container>
      }
    </>
  )
}

export default EventList

export const query = graphql`
  query WpEvents {
    wp {
      seo {
        schema {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    seo: wpPage(id: { eq: "cG9zdDoxNTMz" }) {
      ...SeoFragment
    }
    events: allWpEvent(
      sort: { fields: [startDate, eventInfo___order], order: ASC }
    ) {
      edges {
        event: node {
          id
          uri
          content
          excerpt
          title
          slug
          startDate
          endDate
          eventsCategories {
            nodes {
              databaseId
              name
            }
          }
          tags {
            nodes {
              id
              name
            }
          }
          organizers {
            nodes {
              title
            }
          }
          venue {
            title
          }
          protagonists {
            moderation {
              ... on WpProtagonist {
                id
                #title
                member {
                  category
                  jobtitle
                  title
                  firstname
                  lastname
                }
                featuredImage {
                  node {
                    id
                    title
                    filename
                    slug
                    uri
                  }
                }
              }
            }
            together {
              ... on WpProtagonist {
                id
                #title
                member {
                  category
                  jobtitle
                  title
                  firstname
                  lastname
                }
                featuredImage {
                  node {
                    id
                    title
                    filename
                    slug
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
